<!-- 编辑弹窗 -->
<template>
  <a-modal
    :width="680"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改电池品牌' : '新建电池品牌'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 4 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 20 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item label="品牌名称:" name="brandName">
            <a-input
              v-model:value="form.brandName"
              placeholder="请输入品牌名称"
              allow-clear
            >
            </a-input>
          </a-form-item>
          <a-form-item label="型号:" name="model">
            <a-input
              v-model:value="form.model"
              placeholder="请输入型号"
              allow-clear
            >
            </a-input>
          </a-form-item>
          <a-form-item label="运营商名称:" name="operatorId">
            <a-select
              v-model:value="form.operatorId"
              placeholder="请选择运营商名称"
              allow-clear
            >
              <a-select-option
                v-for="item in operatorList"
                :key="item.operatorId"
                :value="item.operatorId"
              >
                {{ item.operatorName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="额定电量:" name="ratedCapacity">
            <a-input
              addon-after="ah"
              v-model:value="form.ratedCapacity"
              placeholder="请输入额定电量"
              allow-clear
            >
            </a-input>
          </a-form-item>
          <a-form-item label="额定电压:" name="ratedVoltage">
            <a-input
              addon-after="v"
              v-model:value="form.ratedVoltage"
              placeholder="请输入额定电压"
              allow-clear
            />
          </a-form-item>
          <a-form-item label="电池尺寸:" name="szie">
            <a-input
              addon-after="mm*mm*mm"
              v-model:value="form.szie"
              placeholder="请输入电池尺寸"
              allow-clear
            />
          </a-form-item>
          <a-form-item label="重量:" name="weight">
            <a-input
              addon-after="kg"
              v-model:value="form.weight"
              placeholder="请输入重量"
              allow-clear
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import * as batteryModelApi from '@/api/rnt/batteryModel'
import * as operatorApi from '@/api/rnt/operator'
export default {
  name: 'batteryModelEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data, {
        model: ''
      }),
      // 编辑弹窗表单验证规则
      rules: {
        // brandId: [
        //   {
        //     required: true,
        //     message: '请输入品牌',
        //     type: 'string',
        //     trigger: 'blur'
        //   }
        // ],
        brandName: [
          {
            required: true,
            message: '请输入品牌名称',
            type: 'string',
            trigger: 'blur'
          }
        ],
        model: [
          {
            required: true,
            message: '请输入型号',
            type: 'string',
            trigger: 'blur'
          }
        ],
        operatorId: [
          {
            required: true,
            message: '请选择运营商名称',
            type: 'number',
            trigger: 'blur'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 项目列表
      modules: [],
      // 电池品牌
      brandList: [],
      // 型号
      modelList: [],
      // 运营商名称
      operatorList: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = this.data
        this.isUpdate = true
      } else {
        this.form = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  mounted() {
    this.queryOperatorList()
    // this.getBrandList()
    // this.getModelList()
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          this.form.createUserId = this.$store.state.user.user.userId
          batteryModelApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 查询运营商 */
    queryOperatorList() {
      operatorApi
        .page()
        .then((res) => {
          if (res.code === 0) {
            this.operatorList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>
