<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form
        :model="where"
        :label-col="{ md: { span: 8 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 16 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="5" :md="12" :sm="24" :xs="24">
            <a-form-item label="品牌名称:">
              <a-select
                v-model:value="where.brandName"
                placeholder="请输入品牌名称"
                @change="changeBatteryBrand"
                allow-clear
              >
                <a-select-option
                  v-for="item in brandList"
                  :key="item.brand"
                  :value="item.brandName"
                >
                  {{ item.brandName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="5" :md="12" :sm="24" :xs="24">
            <a-form-item label="型号:">
              <a-select
                v-model:value="where.model"
                placeholder="请输入型号"
                allow-clear
              >
                <a-select-option
                  v-for="item in modelList"
                  :key="item.model"
                  :value="item.model"
                >
                  {{ item.model }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="5" :md="12" :sm="24" :xs="24">
            <a-form-item label="额定电量:">
              <a-input
                v-model:value.trim="where.ratedCapacity"
                placeholder="请输入额定电量"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="5" :md="12" :sm="24" :xs="24">
            <a-form-item label="电池尺寸:">
              <a-input
                v-model:value.trim="where.szie"
                placeholder="请输入电池尺寸"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="4" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table
        ref="table"
        row-key="modelId"
        :datasource="datasource"
        :columns="columns"
        v-model:selection="selection"
        :scroll="{ x: 'max-content' }"
      >
        <template #toolbar>
          <a-space>
            <a-button type="primary" @click="openEdit()">
              <template #icon>
                <plus-outlined />
              </template>
              <span>新建</span>
            </a-button>
            <a-button type="primary" danger @click="removeBatch">
              <template #icon>
                <delete-outlined />
              </template>
              <span>删除</span>
            </a-button>
          </a-space>
        </template>
        <template #action="{ record }">
          <a-space>
            <a @click="openDetail(record)">详情</a>
            <a @click="openEdit(record)">修改</a>
            <a-divider type="vertical" />
            <a-divider type="vertical" />
            <a-popconfirm
              title="确定要删除此分组吗？"
              @confirm="remove(record)"
            >
              <a class="ud-text-danger">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </ud-pro-table>
    </a-card>
    <!-- 编辑弹窗 -->
    <batteryModelEdit
      v-model:visible="showEdit"
      :data="current"
      @done="reload"
    />
    <!-- 详情弹窗 -->
    <brand-detail v-model:visible="showInfo" :data="current || {}" />
  </div>
</template>

<script>
import * as batteryModelApi from '@/api/rnt/batteryModel'
import * as operatorApi from '@/api/rnt/operator'
import batteryModelEdit from './brand-edit'
import brandDetail from './brand-detail.vue'
import { createVNode } from 'vue'
import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'
export default {
  name: 'brandtList',
  components: {
    PlusOutlined,
    DeleteOutlined,
    batteryModelEdit,
    brandDetail
  },
  data() {
    return {
      // 电池品牌
      brandList: [],
      // 型号
      modelList: [],
      name: 'brandtList',
      // 表格列配置
      columns: [
        {
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.table.tableIndex + index
        },
        // {
        //   title: '品牌',
        //   dataIndex: 'brandId',
        //   sorter: true
        // },
        {
          title: '品牌名称',
          dataIndex: 'brandName',
          sorter: true
        },
        {
          title: '型号',
          dataIndex: 'model',
          sorter: true
        },
        {
          title: '运营商名称',
          dataIndex: 'operatorName',
          sorter: true
        },
        {
          title: '额定电量',
          dataIndex: 'ratedCapacity',
          sorter: true
        },
        {
          title: '额定电压',
          dataIndex: 'ratedVoltage',
          sorter: true
        },
        {
          title: '电池尺寸',
          dataIndex: 'szie',
          sorter: true
        },
        {
          title: '重量',
          dataIndex: 'weight',
          sorter: true
        },
        {
          title: '操作',
          key: 'action',
          width: 200,
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {},
      // 表格选中数据
      selection: [],
      // 是否显示编辑弹窗
      showEdit: false,
      // 是否显示详情弹窗
      showInfo: false,
      // 当前编辑数据
      current: null,
      // 编辑表单提交状态
      editLoading: false,
      operatorList: []
    }
  },
  mounted() {
    this.queryOperater()
    this.queryBrandList()
    this.queryModelList()
  },
  methods: {
    /* 详情 */
    openDetail(row) {
      this.current = row
      this.showInfo = true
    },
    datasource(option, callback) {
      batteryModelApi
        .page({
          ...this.where,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    // 电池品牌改变获取对应型号
    changeBatteryBrand(value) {
      this.where.model = null
      batteryModelApi
        .model({ brandName: value })
        .then((res) => {
          if (res.code === 0) {
            this.modelList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询运营商 */
    queryOperater() {
      operatorApi
        .page()
        .then((res) => {
          if (res.code === 0) {
            this.operatorList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询品牌名称 */
    queryBrandList() {
      batteryModelApi
        .brand()
        .then((res) => {
          if (res.code === 0) {
            this.brandList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询型号List */
    queryModelList() {
      batteryModelApi
        .model()
        .then((res) => {
          if (res.code === 0) {
            this.modelList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 刷新表格 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.where = {}
      this.queryBrandList()
      this.queryModelList()
      this.$nextTick(() => {
        this.reload()
      })
    },
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      batteryModelApi
        .deleteById(row.modelId)
        .then((res) => {
          hide()
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的项目吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          const data = { data: this.selection.map((d) => d.modelId) }
          batteryModelApi
            .deleteBatch(data)
            .then((res) => {
              hide()
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.reload()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.message)
            })
        }
      })
    },
    /* 打开编辑弹窗 */
    openEdit(row) {
      this.current = row
      console.log(row)
      this.showEdit = true
    }
  }
}
</script>

<style scoped>
</style>
